$(function(){
    // $('.carousel').carousel({
    //     interval: 5000
    // });

    $("body").on("click", "#menuBtn", function(e){
        var target = $(this).data("target");

        $(target).toggleClass("show");
        $(this).toggleClass("open");
        $("body").toggleClass("hidebar");

        $("#header").toggleClass("open");
        $("#mainmenu").toggleClass("show");
        e.preventDefault();
    })

    $("body").on("click", ".gotop", function(e){
        $("body, html").animate({
            scrollTop: 0
        }, 600);
        e.preventDefault();
    })
    //  uploadfile
    $("body").on("change", ".fileupload", function(e){
        var filename = $(this).val().split("\\");
        filename = filename[filename.length-1];
        var dropzone = $(this).parents(".dropzone");
        var progress_target = dropzone.find(".fileprogress");
        progress_target.find(".filename").html(filename);

        if(filename == ""){
            dropzone.find(".filezone").removeClass("d-none");
            progress_target.addClass("d-none");
            $(this).parents(".form-group").find(".clear_plan").addClass("d-none");
        }else{        
            dropzone.find(".filezone").addClass("d-none");
            progress_target.removeClass("d-none");
            $(this).parents(".form-group").find(".clear_plan").removeClass("d-none");
        }
    });

    $("body").on("click", ".clear_plan", function(event){
        $(this).parents(".form-group").find(".filezone").removeClass("d-none");
        $(this).parents(".form-group").find(".fileprogress").addClass("d-none");
        $(this).addClass("d-none");
        $(this).parents(".form-group").find(".fileupload").val(null);
        event.preventDefault();
    });

    $(".dropzone").on("click", ".reupload", function(e){
        $(this).parents(".form-group").find(".fileupload").click();

        e.preventDefault();
    });
    $('.ai-cs-icon').on('click',()=>{
        aiCsOpen()
    })
    $('#close-ai-cs-body').on('click',()=>{
        $('.ai-cs-body').removeClass('active')
        aiCsWillOpen =false
        sessionStorage.setItem('aiCsWasOpen', 'true');

    })
    function aiCsOpen() {
        $('.ai-cs-body').addClass('active')
        let aiCsContent=document.querySelector('.ai-cs-content') 
        aiCsContent.scrollTop= aiCsContent.scrollHeight
    }
    $(".togglePw").on("click", function(event){
        var pwTarget = $(this).parent("div").find("input");
        if(pwTarget.attr("type") == "password"){
            pwTarget.attr("type","text");
            $(this).find(".eye-close").addClass("d-none");
            $(this).find(".eye-open").removeClass("d-none");
        }else{
            pwTarget.attr("type","password");
            $(this).find(".eye-close").removeClass("d-none");
            $(this).find(".eye-open").addClass("d-none");
        }
        
        event.preventDefault();
    });
  if($('.csr-culture .checkGroups').length > 0){
    $('input[type="radio"]').on('change',function () {
        csrToggleInput($(this))
    })
    $('.csr-culture label:contains("是")').click()
  }
})
function csrToggleInput(e) {
    if(e.next().text()=== '是' || e.next().text()=== '否' ){
        if (e.next().text()=== '是') {
            e.closest('.checkGroups').next().next().removeClass('d-none')
            e.closest('.checkGroups').next().next().find('.form-control').jqBootstrapValidation();
        }
        if(e.next().text()=== '否'){
           e.closest('.checkGroups').next().next().addClass('d-none')
           e.closest('.checkGroups').next().next().find('.form-control').jqBootstrapValidation("destroy2");
        }
    }
}