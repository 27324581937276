// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//hotwire
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require('pdfjs-dist')
require('pdfjs-dist/build/pdf.worker')
import '@master/css';
import './common/notice/notice'
import './common/validation/validation'
import "./common/select2/select2"
import "./bootstrap_custom.js";
import "./common/sweetalert/sweetalert"
import "@fortawesome/fontawesome-free/css/all"
import "./custom.js";
import "@nathanvda/cocoon"

import("./application.scss");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
