import { Controller } from "stimulus"

export default class extends Controller {
  reset() {
    this.element.reset()
    setTimeout(( () => this.scrollToBottom() ),100);
  }
  scrollToBottom(){
    let aiCsContent=document.querySelector('.ai-cs-content') 
    aiCsContent.scrollTop= aiCsContent.scrollHeight
  }
}