import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$(function(){
  if($('#advanced-search').length > 0){
    $('.select').select2({
      width: '100%',
      dropdownParent: $('#advanced-search')
    });
  }else{

    $('.select').select2({
      width: '100%'
    });
  }

  if($('#select-company').length > 0){
    $('#select-company').find('.select').select2({
      width: '100%',
      dropdownParent: $('#select-company')
    });
  }

  if($('#ownerModal').length > 0){
    $('#ownerModal').find('.select').select2({
      width: '100%',
      dropdownParent: $('#ownerModal')
    });
  }

  if($('#ownerModal').length > 0){
    $('#ownerModal').find('.select').select2({
      width: '100%',
      dropdownParent: $('#ownerModal')
    });
  }

  if($(".columnModal").length > 0){
    $('.columnModal').find('.select').select2({
      width: '100%',
      dropdownParent: $('.columnModal')
    }).on('change', function(e){
      if(this.value == 1){
        $(".option-list").removeClass("d-none");
      }else{
        $(".option-list").addClass("d-none");
      }
    });
  }
  if($("#toggleBefore").length > 0){
    $('#toggleBefore').on('change',function () {
      if($(this).prop('checked')){
        $('.isBefore').addClass('d-none')
        $('.noBefore').removeClass('d-none')
      }else{
        $('.isBefore').removeClass('d-none')
        $('.noBefore').addClass('d-none')
        $('#investment_after_case_investment_before_case_id').val('').trigger('change')
      }
    })
    if($('#investment_after_case_investment_after_investee_company_id').val()){
      $('#toggleBefore').click()
    }
  }

})

